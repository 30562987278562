import nationwide from 'assets/img/nationwide-logo.png';
import builders from 'assets/img/Builders.png';
import thehartford from 'assets/img/TheHartford.png';
import amtrust from 'assets/img/AmTrust.png';

const CARRIER_IMAGE_FILE_NAME = {
    Nationwide: nationwide,
    Builders: builders,
    'The Hartford': thehartford,
    AmTrust: amtrust,
    '01 Demo Carrier': thehartford,
    'QA TEST': nationwide,
    'Sacramento carrier INC': nationwide,
};

export default CARRIER_IMAGE_FILE_NAME;
